@import './assets/styles/main';

body {
  margin: 0;
  padding: 0;
  color: #000;
  min-height: 100vh;
  // background-color: $pale-gray;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  // overflow-x: hidden;
  // overflow-y: hidden;
  overflow: auto;
  // -webkit-overflow-scrolling: touch;
}

// [data-placeholder]:empty:before {
//   content: attr(data-placeholder);
//   color: rgb(103, 103, 103);
//   font-size: 13px;
// }

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

[contentEditable]:empty:before {
  content: attr(placeholder);
  color: rgb(103, 103, 103);
  font-size: 13px;
}

body::-webkit-scrollbar {
  display: none;
}
